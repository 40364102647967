export const smgPantone = {
    white:'#000000',
    red:'#EA0029'
}

export const smgColorTheme = {
    primary:smgPantone.white,
    secondary:smgPantone.red
}

export const smgStatusColorTheme = {
    cancelled: '#ee7a7d',
    pending: '#FF9932',
    inProgress:'#666666',
    finished: '#3B3B3B',
    partial: '#3B3B3B',
    downloaded:'#009688'
}