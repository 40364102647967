import React, { Fragment, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Avatar, Button, Divider, LinearProgress, Skeleton, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ArrowBack, ArrowForward, Person, Save,AutoAwesome, InfoRounded } from "@mui/icons-material";
import { format, parseISO } from 'date-fns';

import '../styles/Views.css';
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentOrder } from "../state/selectors/currentOrder";
import { updateCustomerMeasurements, updateMeasurements } from "../state/handlers/measurements";
import { sampleLocators, updateCustomerSamples, updateSamples } from "../state/handlers/samples";
import { selectProfile } from "../state/selectors/profile";
import { MeasurementCard } from "./MeasurementCard";
import { SampleCard } from "./SampleCard";
import _ from "underscore";
import { translate } from "../helpers/genders";

const Sample = () => {

    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selected,setSelected] = useState(0);

    const profile = useSelector(selectProfile);
    const isCustomer = profile.data?.role.code === 'customer';
    const customerId = profile.data?.customer?.id;

    const {order,patient,samples,measurements,status} = useSelector(selectCurrentOrder);
    const measurementsWithoutSamples = measurements.filter(m=>!m.sample_id);

    const canEdit = !isCustomer || (isCustomer && (order?.status === 'new'));

    const someNotVerified = samples.some(s=>!s.verified);

    const handleNext = () => {
        if (samples.length>=selected){
            setSelected(selected+1);
        }
    };

    const handleSave = () => {
        if (isCustomer){
            dispatch(updateCustomerSamples(customerId,order.id, samples));
            dispatch(updateCustomerMeasurements(customerId, order.id, measurements));
        }

        if (!isCustomer){
            dispatch(updateSamples(order.id, samples));
            dispatch(updateMeasurements(order.id, measurements));
        }
    }

    const handleLocators = () => {
        dispatch(sampleLocators(customerId,samples));
    };

    const handleContinue = () =>{
        navigate('/order');
    }
    
    return(
        <Fragment>
            <div className="column-container" style={{height:'90vh'}} >
                <div className="header row-container" style={{paddingTop:'1em'}}>
                    <div className="column-container " style={{paddingRight:'1em',width:'15em'}}> 
                        <Box fontWeight="bold">
                            {order.number && <Typography variant="h6" color="text.black">{`Protocolo ${order.number}`}</Typography>}
                            {order.number && <Typography variant="body2" color="text.gray">{`Orden Id ${order.id}`}</Typography>}
                            {!order.number && <Typography variant="h6" color="text.black">{`Oden Id ${order.id}`}</Typography>}
                        </Box>
                        <Box fontWeight={750}>
                            <Typography color="text.gray" variant="body2">{format(parseISO(order?.date), 'dd/MM/yyyy')}</Typography>
                        </Box>
                    </div>
                    <Divider orientation="vertical"  sx={{ height:"5.5em"}} />
                    <div className="row-container" style={{marginLeft:'2em'}}>
                        <div className="column-container " style={{paddingRight:'1em',width:'20em'}}> 
                            {_.isEmpty(patient) &&                        
                                <div className="column-container flex-1">
                                    <Skeleton/>
                                    <Skeleton/>
                                    <Skeleton/>
                                    <Skeleton/>
                                    <Skeleton/>
                                </div>
                            }

                            {!_.isEmpty(patient) &&                        
                                <div className="column-container flex-1">


                                        <Box fontWeight="medium">
                                            <Typography variant="h6" color="text.black">{`${patient.first_name} ${patient.last_name}`}</Typography>
                                        </Box>
                                        <Typography color="text.gray" variant="body2">{`Identificacion ${patient.number}`}</Typography>
                                        <Typography color="text.gray" variant="body2">{`Fecha Nac. ${format(parseISO(patient.birth_day), 'dd/MM/yyyy')}`}</Typography>
                                        <Typography color="text.gray" variant="body2">{`Edad ${patient.age}`}</Typography>
                                        <Typography color="text.gray" variant="body2">{translate(patient.gender)}</Typography>
                                        
                                </div>
                            }

                        </div>
                    </div>
                    <div className="grow"></div>
                    <div className="column-container align-right">
                        <Link to={`/order/${order.id}`} style={{textDecoration:'none'}}>
                            <Button 
                                color="primary"
                                startIcon={<ArrowBack color="secondary" />}
                                sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em',  width:'15em', marginBottom:'1em'}}>
                                    <Box fontWeight="medium">
                                        <Typography>Volver a la orden</Typography>
                                    </Box>
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="progress-container margin-bottom" >{(status.loadingSamples || status.loadingMeasurements) && <LinearProgress/>}</div>
                <div className="body grow" style={{overflowY:'scroll'}}>
                    {
                        measurementsWithoutSamples.length > 0 &&
                        <div style={{marginBottom:'2em'}}>
                            <Typography variant="h6">Mediciones o datos requeridos:</Typography>
                            <div className="row-container margin">
                                {measurementsWithoutSamples.map(measurement => <MeasurementCard key={measurement.id} measurement={measurement} disabled={!canEdit}/>)}
                            </div>
                        </div>
                    }
                    {
                        <div>
                            <Typography variant="h6">Muestras:</Typography>
                            {
                                samples.map((sample,index)=><SampleCard key={sample.id} index={index} order={order} samples={samples} sample={sample} duplicated={samples.filter(s=>s.locator === sample.locator && !_.isEmpty(s.locator)).length>1} disabled={!canEdit} selected={selected===index} next={handleNext}/>)
                            }
                        </div>
                    }
                </div>
                <div className="header column-container grow">
                    <div className="header row-container grow">
                        <div className="margin">
                            <Button 
                                variant="contained"
                                color="secondary"
                                endIcon={<AutoAwesome/>}
                                onClick={()=>handleLocators()}
                                disabled={!isCustomer || order.samplesFilled || !canEdit}
                                sx={{height:'2.5em', textTransform:'none', paddingLeft:'1em', paddingRight:'1em', width:'15em', marginBottom:'1em'}}
                                
                            >
                                <Box fontWeight="medium">
                                    <Typography>Automatico</Typography>
                                </Box>
                            </Button>
                        </div>
                        <div className="grow"></div>
                        <div className="margin">
                            <Button 
                                variant="contained"
                                color="secondary"
                                endIcon={<Save/>}
                                onClick={()=>handleSave()}
                                sx={{height:'2.5em', textTransform:'none', paddingLeft:'1em', paddingRight:'1em', width:'15em', marginBottom:'1em'}}
                                disabled={!canEdit || !status.editedSamples || someNotVerified}
                            >
                                <Box fontWeight="medium">
                                    <Typography>Guardar</Typography>
                                </Box>
                            </Button>
                        </div> 
                        <div className="margin">
                            <Button 
                                color="primary"
                                endIcon={<ArrowForward color="secondary" />}
                                disabled={status.loadingSamples || status.loadingMeasurements}
                                onClick={()=>handleContinue()}
                                sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em',  width:'15em'}}>
                                    <Box fontWeight="medium">
                                        <Typography>Continuar</Typography>
                                    </Box>
                            </Button>
                        </div> 
                    </div>
                    {
                        !canEdit && 
                        <div className="row-container" style={{marginLeft:'2em'}}>
                            <InfoRounded/>
                            <Box fontWeight="medium">
                                <Typography variant="body2" style={{marginLeft:'0.5em'}}>La orden ya fue enviada. No se admiten cambios.</Typography>
                            </Box>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default Sample;