import React, { useState } from 'react';
import {  Download, Visibility } from '@mui/icons-material';
import { Button, LinearProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerResults, fetchCustomerResultsExplorerOrders, fetchResults, fetchResultsExplorerOrders, markAsDownloadedCustomerOrder, selectCurrentResultsExplorerOrder } from "../state/handlers/resultsExplorer";

import { selectProfile } from '../state/selectors/profile';
import { ResultsDialog } from './results/ResultsDialog';
import OrderStatus from './OrderStatus';
import { translatePriority } from '../helpers/priorities';
import ResultsSearch from './ResultsSearch';
import { selectOrders } from '../state/selectors/resultsExplorer';
import { fetchCustomerOrderLastReport, fetchOrderLastReport } from '../state/handlers/document';

const ResultsExplorer = () => {

    const apiRef = useGridApiRef();
    const dispatch = useDispatch();
    const [index, setIndex] = useState(1);

    const profile = useSelector(selectProfile);
    const isCustomer = profile.data?.role.code === 'customer';
    const customerId = profile.data?.customer?.id;

    const [openResultDialog,setOpenResultDialog] = useState(false);
    
    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 30,
            sortable: false,
            type: 'string',
            flex: 0.3,
        },
        {
            field: 'number',
            headerName: 'Protocolo',
            sortable: false,
            flex: 0.5,
            minWidth: 110
        },
        {
            field: 'samples',
            headerName: 'Muestras',
            sortable: false,
            flex: 1,
            minWidth: 110,
            renderCell: (params) => <div className="column-container" style={{paddingTop:params.row.samples.length > 0 ? '1em' : '2em', paddingBottom:params.row.samples.length > 0 ? '1em' : '2em'}}>{params.row.samples.map(sample => <div>{`${sample.locator || '--'} (${sample.sample_type.name})`}</div>)}</div> ,
        },
        {
            field: 'patient_number',
            headerName: 'Identificacion',
            sortable: false,
            flex: 0.5,
            minWidth: 110,
            renderCell: (params) => params.row.patient && `${params.row.patient.number}`,
        },
        {
            field: 'patient',
            headerName: 'Nombres',
            sortable: false,
            flex: 0.5,
            minWidth: 110,
            renderCell: (params) => params.row.patient && `${params.row.patient.last_name} ${params.row.patient.first_name}`,
        },
        {
            field: 'priority',
            headerName: 'Prioridad',
            sortable: false,
            type: 'string',
            flex: 0.3,
            minWidth: 85,
            valueGetter: (params) => translatePriority(params.row.priority)
        },
        {
            field: 'batch_id',
            headerName: 'Lote',
            sortable: false,
            type: 'string',
            width: 120,
        },
        {
            field: 'date',
            headerName: 'Fecha',
            sortable: false,
            flex: 0.5,
            valueGetter:(params)=>format(parseISO(params.value), 'dd/MM/yyyy'),
            minWidth: 110
        },
        {
            field: 'status',
            headerName: 'Estado',
            sortable: false,
            flex: 0.7,
            renderCell: (params) => <OrderStatus status={params.row.status}/>,
            minWidth: 110
        },
        {
            field: 'view_result',
            headerName: 'Resultados',
            sortable: false,
            renderCell: (params) => (params.row.status === 'partial' || params.row.status === 'finished' || params.row.status === 'downloaded') && <Button  onClick={()=>handleSelect(params)} startIcon={<Visibility style={{color:'#696969'}}/>}/>,
        },
        {
            field: 'download_result',
            headerName: 'Resultado',
            sortable: false,
            renderCell: (params) => (params.row.status === 'finished' || params.row.status === 'downloaded' || params.row.status === 'posted') && <Button  onClick={()=>handleDownload(params.row.id)} startIcon={<Download style={{color:'#696969'}}/>}/>,
        }
    ];

    const {loading, current, list} = useSelector(selectOrders);
    const data = {rows:list, columns:columns};

    const handleSelect = (params) => {

        const index = apiRef.current.getRowIndex(params.row.id);

        dispatch(selectCurrentResultsExplorerOrder(params.row));

            if(isCustomer){
                dispatch(fetchCustomerResults(customerId,params.row.id));
                dispatch(markAsDownloadedCustomerOrder(customerId,params.row.id));
            }

            if(!isCustomer){
                dispatch(fetchResults(params.row.id));
            }

        setIndex(index);
        setOpenResultDialog(true);
    };

    const handleNext = () => {
        if(!isLastSelected){
            const order = apiRef.current.getRow(apiRef.current.getRowIdFromRowIndex(index + 1));
            apiRef.current.selectRow(order.id);
            
            dispatch(selectCurrentResultsExplorerOrder(order));

            if(isCustomer){
                dispatch(fetchCustomerResults(customerId,order.id));
                dispatch(markAsDownloadedCustomerOrder(order.id));
            }

            if(!isCustomer){
                dispatch(fetchResults(order.id));
            }
            
            setIndex(index+1);
        }
    };

    const handlePrevious = () => {
        if(!isFirstSelected){
            const order = apiRef.current.getRow(apiRef.current.getRowIdFromRowIndex(index - 1));
            apiRef.current.selectRow(order.id);
            
            dispatch(selectCurrentResultsExplorerOrder(order));
            
            if(isCustomer){
                dispatch(fetchCustomerResults(customerId,order.id));
                dispatch(markAsDownloadedCustomerOrder(order.id));
            }

            if(!isCustomer){
                dispatch(fetchResults(order.id));
            }
            setIndex(index-1);
        }
    };

    const handleSearch = (filter) => {

        if(isCustomer){
            dispatch(fetchCustomerResultsExplorerOrders(customerId,filter));
        }
        
        if(!isCustomer){
            dispatch(fetchResultsExplorerOrders(filter));
        }
    }

    const handleDownload = (order) => {

        if (isCustomer){
            dispatch(fetchCustomerOrderLastReport(customerId, order));
        }

        if (!isCustomer){
            dispatch(fetchOrderLastReport(order));
        }
    }

    const isFirstSelected = index === 0;
    const isLastSelected = apiRef?.current?.getRowsCount !== undefined ? index >= apiRef.current.getRowsCount() -1   : true 

    return ( 

        <div className="column-container all-height">
            <div className="header row-container align-center" style={{height:'4em'}}>
                <div>
                    <Typography variant="h5" color="text.black">
                        <Box fontWeight={750}>Resultados</Box>
                    </Typography>
                </div>
            </div>

        <div>{loading && <LinearProgress/>}</div>

        <div className='row-container body grow padding'>
            <div className='flex-1'>
                <ResultsSearch onSearch={handleSearch}/>
            </div>
            <div className="flex-4 body grow padding">
                <DataGridPro
                    {...data}
                    apiRef={apiRef}
                    headerHeight={40}
                    getRowHeight={()=>'auto'}
                    disableMultipleSelection
                    disableColumnMenu
                    disableColumnSelector
                    disableMultipleColumnsSorting
                    hideFooter
                />
            </div>
        </div>

        {
            openResultDialog && 
            <ResultsDialog 
                open={openResultDialog} 
                data={current} 
                handleClose={()=>setOpenResultDialog(false)} 
                handleNext={handleNext} 
                handlePrevious={handlePrevious} 
                isFirst={isFirstSelected}
                isLast={isLastSelected}
            />
        }

    </div>

     );
}
 
export default ResultsExplorer;