import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import { IconButton,  LinearProgress,  Typography } from "@mui/material";


import '../styles/Views.css';
import { useTheme } from "@emotion/react";
import { fetchTechnicalNote } from "../state/handlers/catalogs";
import { selectCatalogs, selectTechnicalNote } from "../state/selectors/catalogs";

const TechnicalNote = ({studyType}) => {

    const dispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        dispatch(fetchTechnicalNote(studyType.code));    
    }, []);

    const technicalNote = useSelector(selectTechnicalNote(studyType.code));
    const {status} = useSelector(selectCatalogs);

    return (
        <div className="column-container" style={{minWidth:'20em'}}>

            <Typography variant='h6' style={{marginBottom:'1em'}}>{studyType.name}</Typography>
            <Typography variant='h6' style={{backgroundColor:theme.palette.background.header}}>Información General</Typography>
            <Typography>{`Código ${studyType.code}`}</Typography>

            { 
                technicalNote && 
                <Fragment>
                    <Typography>{`Método ${technicalNote.method}`}</Typography>
                    <Typography>{`Informe ${technicalNote.dueDays}`}</Typography>
                    <Typography variant='h6' style={{backgroundColor:theme.palette.background.header}}>Información Técnica</Typography>
                    <Typography>{`Acondicionamiento ${technicalNote.conditioning}`}</Typography>
                    <Typography>{`Istrucciones para el derivante ${technicalNote.customerInstructions}`}</Typography>
                    <Typography>{`Istrucciones para la extraccion ${technicalNote.technicalInstructions}`}</Typography>
                    <Typography>{`Volumen de muestra ${technicalNote.sampleVolume}`}</Typography>

                    {
                        technicalNote.sampleTypes.length > 0 && 
                        technicalNote.sampleTypes.map(s=> <Typography>{`Muestra ${s.sampleType} / Tubo ${s.tubeType}` }</Typography>)
                    }
                </Fragment>
            }

            {
                !technicalNote && <Typography variant="h6" style={{marginTop:'2em', marginBottom:'2em'}}>Sin informacion</Typography>
            }

            {   
                status.loadingTechicalNote && <LinearProgress></LinearProgress>
            }

        </div>)
}

export default TechnicalNote;