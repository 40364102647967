export const actionTypes = {
    fetchResultsExplorerOrders: 'FETCH_RESULTS_EXPLORER_ORDERS',
    receiveResultsExplorerOrders: 'RECEIVE_RESULTS_EXPLORER_ORDERS',
    clearResultsExplorerOrders: 'CLEAR_RESULTS_EXPLORER_ORDERS',
    selectCurrentResultsExplorerOrder:'SELECT_CURRENT_RESULTS_EXPLORER_ORDER',
    receiveCurrentResultsExplorerOrder:'RECEIVE_CURRENT_RESULTS_EXPLORER_ORDER',
    clearCurrentResultsExplorerOrder:'CLEAR_CURRENT_RESULTS_EXPLORER_ORDER',
    fetchCurrentResultsExplorerResults:'FETCH_CURRENT_RESULTS_EXPLORER_RESULTS',
    receiveCurrentResultsExplorerResults:'RECEIVE_CURRENT_RESULTS_EXPLORER_RESULTS',
    setResultsExplorerSearchCriteria:'SET_RESULTS_EXPLORER_SEARCH_CRITERIA',
    refreshCurrentResultsExplorerOrder:'REFRESH_CURRENT_RESULTS_EXPLORER_ORDER'
};

export const clearResultsExplorerOrders = { type: actionTypes.clearResultsExplorerOrders };
export const clearCurrentResultsExplorerOrder = { type: actionTypes.clearCurrentResultsExplorerOrder };

