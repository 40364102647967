import { actionTypes } from "../actions/resultsExplorer";
import HttpService from "../../services/HttpService";

export const setResultsExplorerSearchCriteria = (criteria) => ({ type: actionTypes.setResultsExplorerSearchCriteria, criteria });
export const selectCurrentResultsExplorerOrder = (order) => ({ type: actionTypes.selectCurrentResultsExplorerOrder, order });
export const refreshCurrentResultsExplorerOrder = (order) => ({ type: actionTypes.refreshCurrentResultsExplorerOrder, order });

export const receiveResultsExplorerOrders = (orders) => ({ type: actionTypes.receiveResultsExplorerOrders, orders });
export const receiveCurrentResultsExplorerResults = (samples) => ({ type: actionTypes.receiveCurrentResultsExplorerResults, samples });

export const fetchResultsExplorerOrders = (filter) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchResultsExplorerOrders });
        return HttpService.getInstance().getData(`results/explorer?${filter}`, 'user-token')
            .then(response => {
                dispatch(receiveResultsExplorerOrders(response.data));
        })
    }
}

export const fetchCustomerResultsExplorerOrders = (customerId,filter) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchResultsExplorerOrders });
        return HttpService.getInstance().getData(`customers/${customerId}/results/explorer?${filter}`, 'user-token')
            .then(response => {
                dispatch(receiveResultsExplorerOrders(response.data));
        })
    }
}

export const fetchResults = (orderId) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchCurrentResultsExplorerResults });
        return HttpService.getInstance().getData(`orders/${orderId}/results`, 'user-token')
            .then(response => {
                dispatch(receiveCurrentResultsExplorerResults(response.data.samples));
        })
    }
}

export const fetchCustomerResults = (customerId, orderId) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchCurrentResultsExplorerResults });
        return HttpService.getInstance().getData(`customers/${customerId}/orders/${orderId}/results`, 'user-token')
            .then(response => {
                dispatch(receiveCurrentResultsExplorerResults(response.data.samples));
        })
    }
}

export const markAsDownloadedCustomerOrder = (customerId,orderId) => {
    return (dispatch) => {

        return HttpService.getInstance().getData(`customers/${customerId}/orders/${orderId}/downloaded`, 'user-token')
            .then(response => {
                dispatch(refreshCurrentResultsExplorerOrder(response.data));
        })
    }
} 