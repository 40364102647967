import HttpService from "../../services/HttpService";

export const fetchOrderLastReport = (orderId) => {
    return (dispatch) => {

        return HttpService.getInstance().getPDF(`orders/${orderId}/documents`, 'user-token')
            .then((response) => {

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Informe de resultados ${orderId}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((err)=>{
                alert('No se pudo descargar el informe.')
            })
    }
}

export const fetchCustomerOrderLastReport = (customerId,orderId) => {
    return (dispatch) => {

        return HttpService.getInstance().getPDF(`customers/${customerId}/orders/${orderId}/documents`, 'user-token')
            .then((response) => {

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Informe de resultados ${orderId}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((err)=>{
                alert('No se pudo descargar el informe.')
            })
    }
}