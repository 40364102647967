import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import envs from "../services/EnvsService";
import { addStudy } from "../state/handlers/order";
import { addFavorite, removeFavorite } from "../state/handlers/catalogs";
import { Button } from "@mui/material";
import { AddCircle, CheckCircle, Star, StarBorder, Visibility } from "@mui/icons-material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import TechnicalNoteDialog from "../components/TechnicalNoteDialog";

export const StudiesGrid = ({studiesCatalog,studies, disabled, efectorFilter}) => {
    
    const dispatch = useDispatch();
    const {defaultEfectorId,allowSelectEfector} = envs;
    
    const [selected, select] = useState(undefined);
    const apiRef = useGridApiRef();

    const handleAddStudy = (studyType) => {
        dispatch(addStudy({study_type_id:studyType.id,study_type:{...studyType},efector_id: studyType.efector.id, efector: studyType.efector}));
    };

    const handleAddFavorite = (code) => {
        dispatch(addFavorite(code));
    }

    const handleRemoveFavorite = (code) => {
        dispatch(removeFavorite(code));
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 50,
            hide:true,
            sortable: false,
            type: 'number'
        },
        {
            field: 'code',
            headerName: 'Codigo',
            sortable: false,
            type: 'string',
            minWidth: 35,
        },
        {
            field: 'alt_code',
            headerName: 'Alternativo',
            sortable: false,
            type: 'string',
            minWidth: 35,
        },
        {
            field: 'name',
            headerName: 'Descripcion',
            sortable: false,
            type: 'string',
            flex: 0.5,
            minWidth: 85,
        },
        {
            field: 'fav',
            headerName: 'Favorito',
            sortable: false,
            renderCell: (params) => params.row.isFavorite ? <Button onClick={()=>handleRemoveFavorite(params.row.code)} startIcon={<Star style={{color:'#696969'}}/>}/> : <Button onClick={()=>handleAddFavorite(params.row.code)} startIcon={<StarBorder style={{color:'#696969'}}/>}/>,
            
        },
        {
            field: 'view_file',
            headerName: 'Ficha',
            sortable: false,
            renderCell: (params) => <Button onClick={()=>select(params.row)} startIcon={<Visibility style={{color:'#696969'}}/>}/>,
            
        },
        {
            field: 'add',
            headerName: 'Agregar',
            sortable: false,
            renderCell: (params) => 
            
            studies?.some(study => study.study_type.code === params.row.code) ? 
                <Button disabled={disabled} startIcon={<CheckCircle style={{color:'#03716A'}} />}/> : 
                !disabled && <Button disabled={disabled} startIcon={<AddCircle style={{color:'#696969'}}  onClick={()=> handleAddStudy(params.row)} />}/>,
            
        }
    ];

    const data = {rows:studiesCatalog, columns:columns}

    return(
            <Fragment>
                <DataGridPro
                    style={{backgroundColor:'white'}}
                    {...data}
                    apiRef={apiRef}
                    headerHeight={40}
                    rowHeight={40}
                    disableMultipleSelection
                    disableColumnMenu
                    disableColumnSelector
                    disableSelectionOnClick
                    disableMultipleColumnsSorting
                    hideFooter
                />

                {
                    selected &&
                    <TechnicalNoteDialog open={selected} studyType={selected} onClose={()=>select(undefined)}/>
                }
            </Fragment>
    )
}
